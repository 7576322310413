import HLoading from "@/components/ui/HLoading";
import HAppLayout from "@/components/common/HAppLayout";
import useSWR from "swr";
import accounts from "@/services/module/accounts";
import { withPageAuthRequired } from "@icanid/icanid-sdk-nextjs/client";

const Home = () => {
  const { data, isLoading } = useSWR(
    "/api/accounts/profile",
    accounts.getProfile,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  if (data) {
    window.location.href = "/account";
    return (<></>);
  }
  
  if (!data && !isLoading) {
    window.location.href = "/api/auth/login";
    return (<></>);
  }

  return (
    <HAppLayout>
      {isLoading && (
        <div className="h-[70vh] relative">
          <div className="absolute top-0 left-0 right-0">
            <HLoading />
          </div>
        </div>
      )}
    </HAppLayout>
  );
};

export default withPageAuthRequired(Home);
